<template>
  <div class="window-lives">
    <div class="window-lives-inner" v-show="isFour">
      <div class="line-wrapper" v-for="n in 2" :key="n" style="max-height: 50%">
        <template v-for="row in 2">
          <div class="live-item" :key="row">
            <LiveItem
              v-if="isFour"
              :config="
                config.fourLiveList[(n - 1) * 2 + row - 1] || notLiveConfig
              "
            />
          </div>
        </template>
      </div>
    </div>
    <div class="window-lives-inner nine overflow-y" v-if="isNine">
      <div
        class="line-wrapper"
        v-for="(n, nIndex) in allLines < 3 ? 3 : allLines"
        :key="nIndex"
        style="max-height: 33%"
      >
        <template v-for="row in getRowLength(n)">
          <div class="live-item" :key="row">
            <LiveItem
              :config="config.liveList[(n - 1) * 3 + row - 1] || notLiveConfig"
            />
          </div>
        </template>
      </div>
      <!-- <div class="no-data" v-if="!config.liveList.length">no data.</div> -->
    </div>
    <div class="big" v-if="isBig">
      <div class="big-top">
        <div class="big-left">
          <BigLiveItem
            :config="
              config.activeBigLive || config.liveList[0] || notLiveConfig
            "
          />
        </div>

        <div class="big-right">
          <template v-for="n in 3">
            <div
              class="live-item"
              :key="n"
              @click.capture="setActiveBigLive(config.liveList[n - 1])"
            >
              <LiveItem
                :showBottom="false"
                :config="config.liveList[n - 1] || notLiveConfig"
              />
            </div>
          </template>
          <!-- <div class="no-data" v-if="!config.liveList.length">no data.</div> -->
        </div>
      </div>

      <div class="big-bottom">
        <div>
          <template
            v-for="n in config.liveList.slice(3).length < 4
              ? 4
              : config.liveList.slice(3).length"
          >
            <div
              class="live-item"
              :key="n"
              @click.capture="setActiveBigLive(config.liveList.slice(3)[n - 1])"
            >
              <LiveItem
                :showBottom="false"
                :config="config.liveList.slice(3)[n - 1] || notLiveConfig"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- <div class="line-wrapper" v-for="(n, nIndex) in (config.type === 4 ? 2 : 3)" :key="nIndex">
        <template v-for="row in config.type">
          <div class="live-item" v-if="config.type === 4 ? ((row-1) < 2*n && (row-1) >= 2*(n-1)) : ((row-1) < 3*(n) && (row-1) >= 3*(n-1))" :key="row">
            <LiveItem :config="list[row-1]" />
          </div>
        </template>
      </div> -->
  </div>
</template>

<script>
import drone from '@/assets/img/statistics/drone.png'
import msg from '@/assets/img/statistics/message.png'
import LiveTimeWhite from '@/assets/img/statistics/time-white.png'
import PersonnelWhite from '@/assets/img/statistics/personnel-white.png'
import LiveItem from './window-live-item.vue'
import BigLiveItem from './window-big-live-item.vue'
export default {
  name: 'window-lives',
  components: { LiveItem, BigLiveItem },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    // 总共 多少行 一行3个
    allLines() {
      return Math.ceil(
        ((this.config.liveList && this.config.liveList.length) || 0) / 3
      )
    },
    // 4宫格
    isFour() {
      return this.config.pageType === 'four'
    },
    // 9宫格
    isNine() {
      return this.config.pageType === 'nine'
    },
    // 大屏展示
    isBig() {
      return this.config.pageType === 'big'
    },
  },
  //   watch: {
  //     config: {
  //       deep: true,
  //       handler: function (val) {
  //         console.log(val);
  //       },
  //     },
  //   },
  data() {
    return {
      icons: {
        drone,
        msg,
        time: LiveTimeWhite,
        person: PersonnelWhite,
      },
      notLiveConfig: {
        is_publish: 0,
        isWatching: false,
        DRONEMODEL: '',
        name: '',
        create_time: '',
      },
    }
  },
  mounted() {
    console.log('mounted')
  },
  methods: {
    // 本来是计算最后一排显示几个的 现在一排默认3个 没满就显示空闲的 一次新增一排3个
    getRowLength(index) {
      return 3
      // if (index === this.allLines) {
      //   return this.config.liveList.length - (index - 1)*3
      // } else {
      //   return 3
      // }
    },
    getCircleStyles(item) {
      return {
        backgroundColor: item && item.isLive ? '#32E225' : '#FF685B',
      }
    },
    // 抛出当前选中的视频对象
    setActiveBigLive(item) {
      console.log('item', item)
      this.$emit('setActiveBigLive', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.window-lives {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0;
  .window-lives-inner {
    background-color: #000;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0;
    justify-content: space-between;
  }
  .overflow-y {
    overflow-y: auto;
    .line-wrapper {
      flex: 0 0 33.3%;
      &:last-child {
        flex: 0 0 33.4%;
      }
    }
  }
  .nine {
    .live-item {
      flex: 0 0 33%;
      max-width: 33%;
    }
  }
  .big {
    width: 100%;
    height: 100%;
    // flex-direction: row;
    background-color: #000;
    color: #fff;
    padding-left: 10px;
    .big-top {
      display: flex;
      width: 100%;
      height: 640px;
      // justify-content: space-between;
      .big-left {
        width: calc(75% - 10px);
        height: 100%;
      }
      .big-right {
        width: 25%;
        height: 100%;
        padding-left: 10px;
        .live-item {
          border-bottom: 10px solid #000;
          cursor: pointer;
          // margin-bottom: pcRem(2);
          height: 33.3%;
          &:last-child {
            // margin-bottom: 0;
            border-bottom: 0;
          }
        }
      }
    }
    .big-bottom {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      width: 100%;
      margin-top: 10px;
      height: calc(100% - 645px);
      // display: flex;
      // justify-content: space-around;
      > div {
        height: 100%;
        display: inline;
        zoom: 1;
        min-width: 200px;
      }
      .live-item {
        display: inline-block;
        // border-bottom: 1px solid #fff;
        cursor: pointer;
        // margin-bottom: pcRem(2);
        margin-right: 10px;
        width: calc(25% - 10px);
        height: 100%;
        &:last-child {
          // margin-bottom: 0;
          border-bottom: 0;
        }
      }
    }
    .big-bottom::-webkit-scrollbar {
      height: 10px;
    }
  }
  .line-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;
    &:last-child {
      .live-item {
        margin-bottom: 0;
      }
    }
    .live-item {
      flex: 1;
      background-color: #fafafa;
      margin: 0 0.5% 0.5% 0;
      color: #fff;
      font-size: pcRem(12);
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
